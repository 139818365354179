import React from 'react'
import { Link } from 'gatsby'

import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LogoSpreadIcon, Stones, StoneStyles } from 'components/icons'
import { ApplyForm, StepIcon } from 'components/pages/CareersPage'
import { Nav } from 'components/nav'
import { BackgroundWithGrid } from '../components/layout'
import Helmet from 'react-helmet'
import { HeaderLink } from '../components/semanticdata'
import Analytics from '../components/analytics'

const VentureSummitPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <Helmet
      title={`Venture Summit`}
      titleTemplate={`Venture Summit`}
      meta={[
        {
          name: `title`,
          property: `og:title`,
          content: 'Venture Summit',
        },
        {
          name: `description`,
          property: `og:description`,
          content: `Pure passion for technology and product growth.`,
        },
        {
          property: `og:site_name`,
          content: `Tumba Solutions`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `robots`,
          content: `noindex,nofollow`,
        },
      ]}
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Looking for a technical partner?</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[1]} />
        <h2 className="text-3xl lg:text-4xl text-primary-1">About us</h2>
        <p className="mt-4 sm:mt-6 mb-16 text-lg lg:text-xl font-normal tracking-normal">
          Tumba Solutions is a trusted software development partner for ingenious startups, SMEs and enterprise businesses worldwide. We have more than 20 years tech experience in different industry domains. Most prominent engagements are in autonomous driving
          industry, news publishing, sports and wellness and blockchain.
        </p>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <p className="text-xl lg:text-2xl text-primary-1">Technologies</p>
            <p className="mt-4 text-lg lg:text-xl font-normal tracking-normal">We deliver top-notch software solutions, including mobile development, full stack, big data, infrastructure, blockchain, AR & VR, distributed systems, DevOps and many more.</p>
          </div>
          <div>
            <p className="text-xl lg:text-2xl text-primary-1">Services</p>
            <p className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              Company involvements vary from designing and developing mobile applications and MVPs to building distributed systems at scale while transforming existing products with cutting edge technologies and concepts.
            </p>
          </div>
        </div>
      </Section>
      <BackgroundWithGrid image="venturesummit-hero" bgBottom gridRow={3} />
      <Deco className="z-0 bg-black bg-opacity-50" gridRow={3} />
      <Section className="z-20 my-40 h-72" gridRow={3}>
        <h2 className="text-3xl lg:text-4xl text-primary-1">Request a quote and product workshop</h2>
        <ApplyForm type="project" destination="contact+venturesummit" subjectHint="Project name" messageHint="Project details (client state, timeline, technologies, etc.)" />
      </Section>
      <Section className="mt-16" gridRow={4}>
        <h2 className="text-4xl lg:text-5xl text-shadow">So what's next?</h2>
        <div className="mt-16 grid gap-16 grid-cols-1 lg:grid-cols-3 text-lg text-primary-2">
          <div>
            <div className="float-left sm:float-none mr-4">
              <StepIcon>1</StepIcon>
            </div>
            <p>Review</p>
            <p className="mt-2 font-normal tracking-normal">We will read carefully your submitted request. In case you have technical documentation or project presentation, we'll go through them as well, just send them.</p>
          </div>
          <div>
            <div className="float-left sm:float-none mr-4">
              <StepIcon rotate={-75}>2</StepIcon>
            </div>
            <p>Intro Meeting</p>
            <p className="mt-2 font-normal tracking-normal">We will start with short company introduction and follow up with detailed overview on your current experience, project milestones and timeline. We will discuss opportunities.</p>
          </div>
          <div>
            <div className="float-left sm:float-none mr-4">
              <StepIcon rotate={90}>3</StepIcon>
            </div>
            <p>Product Workshop</p>
            <p className="mt-2 font-normal tracking-normal">We will meet for a 2 hours complimentary product workshop with our best tech experts. We will align expectations, discuss your immediate needs and provide practical solutions.</p>
          </div>
        </div>
        <LogoSpreadIcon className="m-auto mt-16 h-20" />
      </Section>
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default VentureSummitPage
